mat-toolbar.header {

  mat-toolbar-row {

    img.logo {
      width: 6em;
      padding: 0 16px;
    }
  }
}

div.conditional-display {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}
