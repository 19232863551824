
.mat-button[mat-button] {
    .mat-button-focus-overlay {
        background: none;
    }
}

.mat-flat-button[mat-flat-button].primary {
    background-color: $primary;
    color: #fff;

}

.mat-flat-button[mat-flat-button].primary.mat-button-disabled {
    background-color: $button-disabled-background;

}

.mat-flat-button[mat-flat-button].btn-login {
    width: 100%;
}

.mat-button[mat-button].btn-clear {
    font-weight: 400;
    text-transform: none;
    padding: 0;
}
