.autocomplete-wrapper {
    .dropdown-autocomplete-item-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .autocomplete-remove-btn {
        color: $secondary;
        width: auto;
        margin: 0 28px 0 4px;
    }

    .autocomplete-other {
        margin-top: -14px;
        margin-bottom: 16px;
    }

    .mat-form-field-infix {
        min-width: 270px;
        width: 100%;
    }
}

.mat-button.btn-clear {
    margin-top: -8px;
}