app-clinical-notes {
    position: fixed;

    div.notes-wrapper {
        display: flex;
        flex-direction: column;
        width: map-get($max-widths, p-notes);

        label {
            color: #fff;
            text-align: center;
            padding: 0.25em;
            background-color: $dark-blue;
            border-radius: 5px;
        }

        .mat-form-field-appearance-outline .mat-form-field-flex {
            background-color: $light-blue;
        }

        textarea {
            min-height: 30vh;
        }

        span.formfield-header{
            position: absolute;
            top: -5px;
            width: 100%;
            text-align: center;
            color: #00000099;
            font-size: 75%;
        }
    }
    p.hint {
        display: flex;
        flex-direction: row-reverse;
        color: $dark-grey;
    }
}

@media only screen and (max-width: 1120px) {
    app-clinical-notes div.notes-wrapper {
        width: 100%;
        position: static;

        textarea {
            min-height: 200px;
        }
    }
}
