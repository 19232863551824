.hearing-form {
    .inputs-inline {
        display: flex;
    }

    .input-xs {
        margin: 10px 18px 10px 0;
    }

    .form-subheading {
        margin: 12px 0 5px 0;
    }

    mat-divider {
        margin-bottom: 16px;

    }
    mat-radio-group {
        display: flex;
        flex-direction: column;

        mat-radio-button {
            margin: 4px 0;
        }
    }
}