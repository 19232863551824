.screenings-wrapper {  
    padding: 10px;  
    form {
        margin-bottom: 48px;
    }

    .fraction-bar {
        color: #979797;
        font-size: 30px;
        padding: 0 6px;
    }
}

