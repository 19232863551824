@charset 'utf-8'; //Avoid character encoding issues (ref: https://sass-guidelin.es/)

@import 'abstracts/variables';
@import 'base/typography';
@import 'base/overrides';
@import 'base/theme';
@import 'layout/layout';

// Components
@import 'components/buttons';
@import 'components/add-specialty';
@import 'components/inputs';
@import 'components/patient-chart';
@import 'components/clinical-notes';
@import 'components/screenings';
@import 'components/header';
@import 'components/vision-form';
@import 'components/hearing-form';
@import 'components/labs';
@import 'components/loader';

// HA Styling
@import 'src/app/health-assessment/components/health-assessment/health-assessment.component.scss';

html,
body {
  height: 100%;
  margin: 0;
}


@media only screen and (min-width:  map-get($max-widths, viewport)) {
    body {
      margin: auto;

    }
  }

  .defaultStatusDialog > mat-dialog-container {
    border-top: 5px solid $dark-grey;
    width: 500px;
  }

  .errorDialog > mat-dialog-container {
    border-top: 5px solid red;
    width: 500px;
  }

  .successStatusDialog > mat-dialog-container {
    border-top: 5px solid $success-green;
    width: 500px;
  }


