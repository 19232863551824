.dashboard-content {
    display: flex;
    padding: 1rem;
    position: relative;
    height: calc(100% - 156px);
    overflow: auto;

    .column{
        min-width:22%;
        position: relative;
    }

    .column + .column {
        margin-left: 8vw;
        flex-grow:1;
      }
}

mat-toolbar.mat-toolbar.patient-info {
    justify-content: space-between;

    .info-column{
        display: flex;
        flex-direction: column;
        font-weight: normal;
        padding: 0 16px;
        font-size: 14px;;
    }
}

div.sticky-header{
    // position: fixed;
    // top: 0;
    // right: 0;
    // left: 0;
    // z-index: 200;
    position: relative;

    nav.mat-tab-header{
        background-color: #fff;
    }
}
