@import "abstracts/variables";
.grid-container {
  height: 100%;
  overflow-y: auto;

  .dashboard-card {
    position: relative;


    .dashboard-card-content {
      text-align: left;

      .panel-action-buttons {
        display: flex;
        justify-content: flex-end;
      }

      mat-accordion.mat-accordion {
        mat-expansion-panel-header.subsection-header {
          color: $primary;
          font-size: 18px;
        }

        .ha-form-wrapper {
          display: flex;
          flex-direction: column;

          .specialist-array {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
          }

          div mat-checkbox{
            display: inherit;
          }

          mat-divider.mat-divider.mat-divider-horizontal {
            display: block;
            position: relative;
          }
        }
      }
    }
  }
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate{
  background: $primary;
}
