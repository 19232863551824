.labs-wrapper {
  margin-top: 14px;
  width: 55vw;
  padding: 10px;

  .mat-table {
    display: block;
  }
  .mat-row,
  .mat-header-row {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: rgba(0,0,0,.12);
    align-items: center;
    min-height: 48px;
    padding: 0 24px;
    width: 100%;
  }
  
  .mat-cell,
  .mat-header-cell {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
    padding: 14px 0;
    margin:0 14px;
  }

  .mat-cell {
    font-weight: normal;
  }
  
  .cell-warning {
    color: red;
    font-weight: 800;
  }

  #inRange {
    font-size: 18px;;
  }

  #outRange {
    font-size: 18px;;
  }

  .cell-comment {
    text-align: center;
  }

  .labs-table {
    margin: 20px 0 38px;
  }

  .lab-info ul {
    list-style-type: none; 
    padding-left: 0;
  }

  .labs-info-msg{
    color: $secondary;
    margin: 10px;
    text-align: center;
  }
}