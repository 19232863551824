$border-tertiary: #858585;
$button-disabled-background: #0000001f;
$dark-blue: #1583d4;
$dark-grey: #545454;
$header-font-color: #808080;
$header-font-size: 19px;
$header-font-sm-xs: 12px;
$header-font-sm: 14px;
$hover-blue: #1683d426;
$light-blue: #dfecfa;
$light-grey: #8585850f;
$mat-checkbox-ripple: ".mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element";
$mat-checkbox: ".mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background";
$primary: #1583d4;
$quaternary: #eeeeee;
$radio-margin-bottom: 10px;
$secondary: #808080;
$success-green: #15D478;
$tertiary: #d8d8d8;
$max-widths: (
    viewport: 1800px,
    p-notes: 320px,
);
$basis: (
    0: 0,
    10: 10%,
    20: 20%,
    30: 30%,
    40: 40%,
);

