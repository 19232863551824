
.visionForm {
    font-size: 0.95em;

    .custom-label {
        margin: 0;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }

    .vision-inner {
        margin-left: 22px;
    }

    .input-xs {
        margin-right: 14px;
    }

    .form-subheading {
        margin: 12px 0 5px 0;
    }

    .vision-inputs-group {
        display: inline-block;
    }

    .input-checkbox {
        margin-top: 18px;
    }
}
