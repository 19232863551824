div[class="input-sm"],
div[class="input-xs"],
div[class="input-md"],
div[class="input-select-sm"],
div[class="input-select-lg"],
div[class="input-autocomplete"] {
    mat-form-field.mat-form-field div.mat-form-field-flex {
        height: 50px;
    }
}

div[class="input-sm"],
div[class="input-xs"],
div[class="input-md"],
div[class="input-autocomplete"] {
    mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0;
    }
}

div[class="input-select-sm"],
div[class="input-select-lg"],
    mat-form-field.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
        transform: initial;
}

div[class="input-select-sm"],
div[class="input-select-lg"],
div[class="input-datepicker"] {
    mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0.5em 0;
    }
}

.input-datepicker {
    mat-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0.5em 0;
    }
}

mat-form-field {
    &.mat-form-field-padding {
        .mat-form-field-infix {
            padding: 0.5em 0;
        }
    }
}

div[class="input-select-sm"],
div[class="input-select-lg"] {
    mat-form-field.mat-form-field {
        margin-right: 12px;
    }
}

div[class="input-select-sm"] mat-form-field.mat-form-field {
    width: 120px;
}

div[class="input-select-lg"] mat-form-field.mat-form-field,
div[class="input-datepicker"] mat-form-field.mat-form-field {
    width: 250px;
}

div[class="input-datepicker"] .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
}

div[class="input-xs"] {
    mat-form-field.mat-form-field .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    mat-form-field.mat-form-field {
        width: 75px;
        padding: 0;
        display: inline-block;
    }
}

div[class="input-sm"] mat-form-field.mat-form-field {
    width: 120px;
    padding-right: 10px;
}

div[class="input-md"] mat-form-field.mat-form-field {
    width: 190px;
    padding-right: 10px;
}

div[class="input-full-width"] mat-form-field.mat-form-field {
    width: 100%;
}

div[class="input-75"] mat-form-field.mat-form-field {
    width: 75%;
}

.mat-form-field-appearance-outline {
    &.mat-focused {
        .mat-form-field-outline-thick {
            color: $tertiary;
        }
    }
}

.mat-list-base .mat-subheader {
    #{$mat-checkbox} {
        background-color: $secondary;
    }
}

.mat-list-base .mat-list-item .mat-line {
    white-space: normal;
}

p.bold.mat-line[mat-line] {
    line-height: normal;
}

// keep checkbox on first line
.align-top .mat-checkbox-inner-container {
    margin-top: 4px !important;
}

mat-checkbox .mat-checkbox-layout{
    white-space: normal;
}

.mat-tab-links {
    justify-content: space-between;
 }

  mat-radio-button.mat-radio-button {
    margin-bottom: $radio-margin-bottom;
  }

.mat-form-field mat-icon.mat-icon {
    color: rgba(0, 0, 0, 0.54);
    height:40px;
    width:40px;
    display: flex;
    align-items: center;
    &:hover{
     cursor:pointer;   
    }
  }

  form mat-label {
    margin: 6px 0 6px 0;
    font-weight: bold;
  }

