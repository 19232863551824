.container {
  display: flex;
  justify-content: center;
  margin: 5% 12% 5% 12.5%;
}

.column {
  display: flex;
  flex-direction: column;
}

.column + .column {
  margin-left: 2%;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.header-column {
  display: flex;
  flex-direction: column;
}

.flex-spacer {
  flex: 1.0 1.0 auto;
}

.justify-content__between {
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.justify-content__center {
  justify-content: center;
}

.justify-content__end {
  justify-content: flex-end;
}

.margin-top__10 {
  margin-top: 10px;
}

.padding-left__0 {
  padding-left: 0;
}

.padding-vertical {
  padding: 1em 0;
}

.row {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}