input {
    height: 30px;
    width: 200px;
}

.input-group {
    margin: 0px 0px 20px 0px;
}

.input-checkbox {
    display: flex;
    align-items: center;

    input[type=checkbox] {
        height: 15px;
        width: 15px;
    }
}

.mat-radio-group {
    display: flex;
    flex-direction: column;

    .mat-radio-label {
        display: flex;
        align-items: center;
        white-space: normal;

        .mat-radio-outer-circle {
            height: 14px;
            width: 14px;
            left: 4px;
            top: 4px;
        }

        .mat-radio-inner-circle {
            height: 14px;
            width: 14px;
            left: 4px;
            top: 4px;
        }
    }
}

mat-form-field.mat-form-field {
  &.large {
    width: 30em;
  }
  &.medium {
    width: 22em;
  }
  &.small {
    width: 15em;
  }
  &.xs {
    width: 7.5em;
  }
}
